<template>
  <div
    :class="[
      'payment-method__container',
      { 'payment-method__container-multiple': methodIsMultiple }
    ]"
    @click="$emit('open-payment-form')"
  >
    <div
      :class="[{ 'fill-height flex-column justify-space-between': !methodIsMultiple }, 'd-flex']"
    >
      <v-img :src="paymentMethod.logo" contain max-height="89px" max-width="89px" class="mr-6" />
      <div :class="[{ 'payment-method__content': !methodIsMultiple }]">
        <v-spacer />
        <div class="payment-method__title">
          {{ paymentTranslation.name }}
        </div>
        <v-spacer />
        <p class="payment-method__description">
          {{ paymentTranslation.description }}
        </p>
      </div>
    </div>
    <div v-if="methodIsMultiple" class="sub-payment-method__container">
      <div
        v-for="(subPaymentMethodImage, i) in paymentMethodImages"
        :key="i"
        class="sub-payment-method__item"
      >
        <v-img :src="subPaymentMethodImage" max-height="100%" max-width="100%" contain />
      </div>
    </div>
  </div>
</template>

<script>
  import paymentTypes from '@/types/payment-types.js'

  export default {
    name: 'PaymentCard',
    props: {
      paymentMethod: {
        type: Object,
        required: true
      }
    },
    computed: {
      methodIsMultiple() {
        const multipleMethods = [paymentTypes.ROBOKASSA, paymentTypes.COINBASE, paymentTypes.STRIPE_CHECKOUT, paymentTypes.CRYPTOMUS]
        return multipleMethods.includes(this.paymentMethod.type)
      },
      paymentTranslation() {
        const foundTranslationsPayment = this.paymentMethod.translations.find(
          ({ locale }) => locale === this.$i18n.locale
        )
        if (!foundTranslationsPayment) {
          const foundEnTranslations = this.paymentMethod.translations.find(
            ({ locale }) => locale === 'en'
          )
          return foundEnTranslations || {}
        }
        return foundTranslationsPayment
      },
      paymentMethodImages() {
        return {
          [paymentTypes.COINBASE]: [
            require('@/assets/payment/usd.svg'),
            require('@/assets/payment/etherium.svg'),
            require('@/assets/payment/usd-coin.svg'),
            require('@/assets/payment/dogecoin.svg'),
            require('@/assets/payment/litecoin.svg'),
            require('@/assets/payment/dai.svg'),
            require('@/assets/payment/bitcoin-cash.svg'),
            require('@/assets/payment/shiba.svg')
          ],
          [paymentTypes.ROBOKASSA]: [
            require('@/assets/payment/google-pay.svg'),
            require('@/assets/payment/samsung-pay.svg'),
            require('@/assets/payment/u-money.svg'),
            require('@/assets/payment/cards.svg')
          ],
          [paymentTypes.STRIPE_CHECKOUT]: [
            require('@/assets/payment/cards.svg'),
            require('@/assets/payment/google-pay.svg'),
            require('@/assets/payment/apple-pay.svg'),
            require('@/assets/payment/giropay.svg'),
            require('@/assets/payment/alipay.svg'),
            require('@/assets/payment/bancontact.svg'),
            require('@/assets/payment/ideal.svg'),
            require('@/assets/payment/przelewy.svg')
          ],
          [paymentTypes.CRYPTOMUS]: [
            require('@/assets/payment/bitcoin.svg'),
            require('@/assets/payment/ton.svg'),
            require('@/assets/payment/usd.svg'),
            require('@/assets/payment/etherium.svg')
          ]
        }[this.paymentMethod.type]
      }
    }
  }
</script>

<style lang="scss" scoped>
  .payment-method {
    &__container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 343px;
      min-height: 234px;
      height: 100%;
      background-color: var(--v-secondary-lighten-base);
      border: 1px solid var(--v-secondary-lighten-base);
      border-radius: 4px;
      padding: 22px 16px 32px 16px;

      .sub-payment-method {
        &__container {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          align-items: center;
          margin-top: 20px;

          @media screen and (max-width: 1253px) {
            justify-content: space-between;
          }
        }
        &__item {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 70px;
          height: 40px;
          background-color: var(--v-white-base);
          border-radius: 4px;
          margin: 2.5px 3.2px;
        }
      }

      &:hover {
        transition-duration: 0.2s;
        box-sizing: border-box;
        box-shadow: 0 15px 10px -15px var(--v-black-base);
        background-color: var(--v-primary-base);
        border: 1px solid var(--v-primary-base);
        cursor: pointer;

        .payment-method__title {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          color: var(--v-white-base);
        }
        .payment-method__description {
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          color: var(--v-secondary-base);
          opacity: 1;
        }
      }

      @media screen and (max-width: 1440px) {
        width: 100%;
      }
    }
    &__container-multiple {
      @media screen and (max-width: 1440px) {
        justify-content: space-between;
        padding-bottom: 15px;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    &__title {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 7px;
    }

    &__description {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.004em;
      opacity: 0.3;
      margin: 0;
    }
  }
</style>
