export default {
  WIRE_TRANSFER: 'wire',
  PAXUM: 'paxum',
  WEBMONEY: 'webmoney',
  CAPITALIST: 'capitalist',
  ROBOKASSA: 'robokassa',
  STRIPE_CHECKOUT: 'stripe_checkout',
  PERFECT_MONEY: 'perfect_money',
  QIWI: 'qiwi',
  COINBASE: 'coinbase',
  SHIFT4: 'shift4',
  MANUAL: 'manual',
  PAYEER: 'payeer',
  CRYPTOMUS: 'cryptomus'
}
